<template>
  <div>
    <v-card class="ml-4">
      <table id="customers">
        <tr>
          <td><p>Graduation Name</p></td>
          <td>
            <p>{{ storeGraduation.name }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Graduation Date</p></td>
          <td>
            <p>{{ storeGraduation.date }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Intake</p></td>
          <td>
            <p>{{ storeGraduation.intake.name }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Status</p></td>
          <td>
            <p>{{ storeGraduation.state }}</p>
          </td>
        </tr>
        <tr>
          <td><p>Approval Status</p></td>
          <td>
            <p>
              {{ storeGraduation.approvalStatus }}
              <span style="float: right"
                ><v-btn
                  link
                  small
                  v-if="
                    storeGraduation.approvalStatus !== 'REQUESTED' &&
                    storeGraduation.approvalStatus !== 'APPROVED'
                  "
                  color="blue"
                  @click="requestDialog = true"
                  >Request graduation Approval</v-btn
                ><v-btn
                  link
                  small
                  v-if="
                    storeGraduation.approvalStatus === 'REQUESTED' && btnControl
                  "
                  color="blue"
                  @click="approveDialog = true"
                  >Approve</v-btn
                ><v-btn
                  link
                  small
                  v-if="
                    storeGraduation.approvalStatus === 'REQUESTED' && btnControl
                  "
                  color="red"
                  @click="rejectDialog = true"
                  >Reject</v-btn
                ></span
              >
            </p>
          </td>
        </tr>
      </table>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="delinquents"
      :page.sync="page"
      :items-per-page="delinquentsPerPage"
      :server-items-length="totalDelinquents"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-col cols="12" sm="3">
            <v-select
              :items="intakes"
              item-text="name"
              item-value="id"
              v-model="intakeId"
              dense
              outlined
              color="grey"
              hint="Filter by Intake"
              persistent-hint
              class="mt-8 mx-4"
              solo
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              :items="allDistricts"
              item-text="name"
              item-value="id"
              v-model="residenceId"
              dense
              outlined
              color="grey"
              hint="Filter by District"
              persistent-hint
              class="mt-8 mx-4"
              solo
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="delinquentFirstName"
              dense
              outlined
              color="grey"
              hint="Filter by Name"
              persistent-hint
              class="mt-8 mx-4"
              solo
            />
          </v-col>
          <v-btn
            class="mt-2"
            elevation="1"
            fab
            small
            @click="loadAlldelinquents"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded disabled right>{{ currentCenter.name }}</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.names="{ item }">
        <p @click="loadProfile(item)" class="btn blue--text">
          {{ item.names }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="graduandDialog(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-card class="ml-4 mt-1"> </v-card>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="requestDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to request for approval ?</p>
          <v-btn link small color="blue" @click="requestGraduationApproval()"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="requestDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to remove this graduand ?</p>
          <v-btn link small color="blue" @click="removeGraduand()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to approve this graduation?</p>
          <v-btn link small color="blue" @click="openApproveCommentDialog"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="approveDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to reject this graduation ?</p>
          <v-btn link small color="blue" @click="openRejectCommentDialog"
            >Yes</v-btn
          >
          <v-btn link small color="blue" @click="approveDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveCommentDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <v-textarea
            filled
            outlined
            auto-grow
            v-model="approvalComment"
            label="Approval Comment"
            rows="3"
            class="mt-4"
            row-height="20"
          ></v-textarea>
          <v-btn link small color="blue" @click="approveGraduation">Yes</v-btn>
          <v-btn link small color="blue" @click="approveCommentDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rejectCommentDialog"
      hide-overlay
      max-width="500px"
      persistent
    >
      <v-card tile outlined>
        <v-card-text>
          <v-textarea
            filled
            outlined
            auto-grow
            v-model="rejectComment"
            label="Reject Comment"
            rows="3"
            class="mt-4"
            row-height="20"
          ></v-textarea>
          <v-btn link small color="blue" @click="rejectGraduation">Yes</v-btn>
          <v-btn link small color="blue" @click="rejectCommentDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showCreate: false,
      deleteDialog: false,
      requestDialog: false,
      approveDialog: false,
      rejectDialog: false,
      approveCommentDialog: false,
      rejectCommentDialog: false,
      approvalComment: null,
      rejectComment: null,
      names: null,
      headers: [
        // {
        //   text: 'ID',
        //   align: 'start',
        //   sortable: false,
        //   value: 'idn'
        // },
        { text: 'Names', value: 'names' },
        { text: 'Age', value: 'delinquent.age' },
        { text: 'Trade', value: 'trade' },
        { text: 'Father Name', value: 'delinquent.fatherName' },
        { text: 'Mother Name', value: 'delinquent.motherName' },
        {
          text: 'Province',
          value: 'apprehension.delinquentResidence.province.name'
        },
        {
          text: 'District',
          value: 'apprehension.delinquentResidence.district.name'
        },
        {
          text: 'Sector',
          value: 'apprehension.delinquentResidence.sector.name'
        },
        { text: 'Cell', value: 'apprehension.delinquentResidence.cell.name' },
        {
          text: 'Village',
          value: 'apprehension.delinquentResidence.village.name'
        },
        { text: 'Homeless', value: 'delinquent.homeless' },
        { text: 'Receiver', value: 'receiverNames' },
        { text: 'Receiver Phone', value: 'delinquent.guardianPhone' },
        { text: 'Action', value: 'actions' }
      ],
      delinquents: [],
      graduations: [],
      graduation: [],
      trainings: [],
      graduandIndex: [],
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      intakes: [],
      intake: null,
      emptyIntake: { id: null, name: '' },
      allDistricts: [],
      emptyDistrict: { id: null, name: '' },
      delinquentItem: null,
      transferId: null,
      errors: null,
      panel: 3,
      delinquentFirstName: null,
      delinquentLastName: null,
      placeOfBirthId: null,
      residenceId: null,
      recordDates: null,
      arrivalDates: null,
      exitDates: null,
      released: null,
      states: null,
      eligibilityStates: null,
      intakeId: null,
      intakeSet: null,
      decisionIds: null,
      physicallyScreened: null,
      screeningApprovalStatuses: null,
      onGraduationList: null,
      graduationId: null,
      expand: null,
      graduationApprovalStatuses: null,
      postRehabDestinationId: null,
      hasOccupation: null,
      organizationId: null,
      sort: 'recordDate,desc'
    }
  },

  computed: {
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'storeGraduation',
      'btnControl'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAlldelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAlldelinquents()
      }
    }
  },
  mounted () {
    this.delinquents = this.storeGraduation.graduands.content
    this.totalDelinquents = this.storeGraduation.graduands.totalElements
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.showPanels = true
    this.loadIntakes()
    this.loadAllDistricts()
    this.loadSingleGraduation()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM',
      setStoreGraduation: 'SET_STORE_GRADUATION'
    }),
    loadAlldelinquents () {
      const pageNumber = this.page - 1
      this.graduationId = this.storeGraduation.id
      this.delinquentLastName = this.delinquentFirstName
      this.expand = 'trainings'
      this.$delinquentService
        .fetchAllTransfersSpecification(
          this.delinquentFirstName,
          this.delinquentLastName,
          this.placeOfBirthId,
          this.residenceId,
          this.recordDates,
          this.arrivalDates,
          this.exitDates,
          this.released,
          this.states,
          this.eligibilityStates,
          this.intakeId,
          this.intakeSet,
          this.decisionIds,
          this.physicallyScreened,
          this.screeningApprovalStatuses,
          this.onGraduationList,
          this.graduationId,
          this.expand,
          this.graduationApprovalStatuses,
          this.postRehabDestinationId,
          this.hasOccupation,
          this.organizationId,
          pageNumber,
          this.delinquentsPerPage,
          this.sort
        )
        .then((response) => {
          if (!response.status) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach((element) => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.receiverNames =
                element.receiver.firstName + ' ' + element.receiver.lastName
              element.trade = element.trainingEnrolments == null ? ''
                : element.trainingEnrolments[0].trainingClass.training.name
              element.delinquent.age =
                parseInt(
                  this.$moment(new Date().toISOString().substr(0, 10)).format(
                    'YYYY'
                  )
                ) -
                parseInt(
                  this.$moment(element.delinquent.dateOfBirth).format('YYYY')
                )
            })
            // console.log(this.delinquents)
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedAssessment = Object.assign({}, this.defaultAssessmentItem)
        this.editedAssessmentIndex = -1
      }, 300)
    },
    loadIntakes () {
      this.$delinquentService.fetchIntakes('OPEN').then((response) => {
        if (!response.status) {
          this.intakes = response
          this.intakes.unshift(this.emptyIntake)
        } else {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    loadAllDistricts () {
      this.$locationService.fetchAllDistricts().then((response) => {
        if (!response.status) {
          this.allDistricts = response
          this.allDistricts.unshift(this.emptyDistrict)
        } else {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    loadSingleGraduation () {
      this.$delinquentService
        .fetchGraduationById(this.storeGraduation.id, 'graduands', 0, 20)
        .then((response) => {
          if (response.id) {
            // console.log(response)
            let index
            response.graduands.content.forEach((element) => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.receiverNames =
                element.receiver.firstName + ' ' + element.receiver.lastName
              element.trade = element.trainingEnrolments == null ? '' : element.trainingEnrolments[0].trainingClass.training.name
              element.delinquent.age =
                parseInt(
                  this.$moment(new Date().toISOString().substr(0, 10)).format(
                    'YYYY'
                  )
                ) -
                parseInt(
                  this.$moment(element.delinquent.dateOfBirth).format('YYYY')
                )
            })
            response.graduands.content.forEach((element) => {
              element.trade = element.trainingEnrolments == null ? '' : element.trainingEnrolments[0].trainingClass.training.name
              for (
                index = 0;
                index < element.trainingEnrolments.length;
                index++
              ) {
                // console.log(element.trainingEnrolments[index].trainingClass.training.name)
                element.traide1 = element.trainingEnrolments == null ? '' : element.trainingEnrolments[index].trainingClass.training.name
              }
            })
            this.setStoreGraduation(response)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    requestGraduationApproval () {
      this.$delinquentService
        .requestGraduationApproval(this.storeGraduation.id)
        .then((response) => {
          if (response.status === 'success') {
            this.loadSingleGraduation()
            this.requestDialog = false
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('The request was received successfully')
          }
        })
    },
    graduandDialog (item) {
      this.graduandIndex = this.delinquents.indexOf(item)
      this.graduandItem = item
      this.deleteDialog = true
    },
    removeGraduand () {
      this.$delinquentService
        .removeGraduand(this.storeGraduation.id, this.graduandItem.id)
        .then((response) => {
          if (response.status === 'success') {
            this.delinquents.splice(this.graduandIndex, 1)
            this.deleteDialog = false
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('The graduand was removed successfully')
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    approveGraduation () {
      const approveObj = {
        id: this.storeGraduation.id,
        approvalStatus: 'APPROVED',
        comment: this.approvalComment
      }
      if (this.approvalComment !== null) {
        this.$delinquentService
          .approveGraduation(approveObj)
          .then((response) => {
            if (response.status === 'success') {
              this.loadSingleGraduation()
              this.approveCommentDialog = false
              this.setSnackbar(true)
              this.setColor('green')
              this.setText('This graduation is approved')
            } else {
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Please give comment')
      }
    },
    rejectGraduation () {
      const rejectObj = {
        id: this.storeGraduation.id,
        approvalStatus: 'REJECTED',
        comment: this.rejectComment
      }
      if (this.rejectComment !== null) {
        this.$delinquentService
          .approveGraduation(rejectObj)
          .then((response) => {
            if (response.status === 'success') {
              this.loadSingleGraduation()
              this.rejectCommentDialog = false
              this.setSnackbar(true)
              this.setColor('green')
              this.setText('This graduation is rejected')
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('green')
        this.setText('Please give comment')
      }
    },
    openApproveCommentDialog () {
      this.approveCommentDialog = true
      this.approveDialog = false
    },
    openRejectCommentDialog () {
      this.rejectCommentDialog = true
      this.rejectDialog = false
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers td p {
  margin-left: 30px;
}

#customers td:nth-child(odd) {
  font-weight: bold;
  width: 30%;
}

#customers td:nth-child(even) {
  width: 70%;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

.dialogbox {
  margin-bottom: 200px;
}
</style>
